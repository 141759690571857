import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import Image from "~/components/image";
import TourList from "~/components/tour-list";

const ActivityPage = ({ data }) => {
	console.log(data);
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);
	// const seo = {
	//   title: data.strapiActivity.name,
	// }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`All ${data.strapiActivity.name} tours`}
				description={`Join us on our ${data.strapiActivity.name} and have an adventure of a lifetime.`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/activities/${data.strapiActivity.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiActivity.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiActivity.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiActivity.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Activities",
						item: "https://artofbicycletrips.com/activities",
					},
					{
						position: 2,
						name: `${data.strapiActivity.name}`,
					},
				]}
			/>
			{data.strapiActivity.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={data.strapiActivity.image}
						alt={data.strapiActivity.image.alternativeText}
						loading="eager"
					/>
				</div>
			)}
			<div>
				<div className="text-center">
					<PageHeading>{data.strapiActivity.name} Tours</PageHeading>
				</div>
				<p className="leading-loose mb-20 font-light md:text-xl md:ml-32 md:mr-32">
					{data.strapiActivity.desc}
				</p>
			</div>
			<div>
				<h2 className="mb-10">
					Ease back into a {data.strapiActivity.name} adventure
				</h2>
				{/* <AlltoursList tours={tours} /> */}
				<TourList tours={flatTours} />
			</div>
		</Layout>
	);
};

export const query = graphql`
	query ActivityQuery($slug: String!) {
		allStrapiTour(
			filter: { activity: { slug: { eq: $slug } } }
			sort: { fields: price }
		) {
			edges {
				node {
					id
					duration
					price
					slug
					title
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}

		strapiActivity(slug: { eq: $slug }, locale: { eq: "en" }) {
			name
			desc
			slug
			image {
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 2.52, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`;

export default ActivityPage;
